<template>
	<div class="withdetails">
		<div class="withdetails-wrap">
			<ul class="withdetails-wrap-tab">
				<li @click="changeTab(0)" :class="{'quanbu':cur == 0}">全部</li>
				<li @click="changeTab(1)" :class="{'quanbu':cur == 1}">提现中</li>
				<li @click="changeTab(2)" :class="{'quanbu':cur == 2}">已提现</li>
			</ul>
			<div v-if="isLoad">
				<div class="withdetails-wrap-show" v-if="tixianlist.length > 0">
					<ul class="withdetails-wrap-show-1">
						<router-link :to="'/shopWithdrawList?id='+ item.order_id" tag="li"
							v-for="(item,index) in tixianlist" :key="index">
							<div class="withdetails-wrap-show-1-top">
								<p>申请提现</p>
								<p>￥{{item.withdraw_amount}}</p>
							</div>
							<div class="withdetails-wrap-show-1-bottom">
								<p>{{item.create_time}}</p>
								<div class="bottom-right">
									<p>
										<router-link
											:to="'/shopWithdrawList?id='+ item.order_id">
											{{item.order_status | btnText}}
										</router-link>
									</p>
									<svg t="1598348555635" class="icon" viewBox="0 0 1024 1024" version="1.1"
										xmlns="http://www.w3.org/2000/svg" p-id="11892" width="16" height="16">
										<path
											d="M377.895 812.748c11.584 9.682 28.84 8.112 38.506-3.48l233.204-279.755c4.248-5.068 6.347-11.286 6.347-17.513 0-6.219-2.1-12.445-6.347-17.513L416.401 214.733c-9.665-11.593-26.93-13.163-38.506-3.48s-13.163 26.896-3.498 38.505L593.022 512 374.397 774.242c-9.682 11.626-8.113 28.807 3.498 38.506z"
											p-id="11893" fill="#aaaaaa" />
									</svg>
								</div>
							</div>
						</router-link>
					</ul>
					<load-more :loadLastText="loadLastText" @getMore="TixianList"></load-more>
				</div>
				<no-data v-else content="暂无记录"></no-data>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		TixianList
	} from "@/http/api.js";
	export default {
		props: {
			userTypes: {
				type: Number,
				default () {
					return 1;
				}
			}
		},
		data() {
			return {
				shopUserInfo: {},
				cur: 0,
				pages: 1,
				loadLastText: true,
				isLoad: false,
				tixianlist: [],
			};
		},
        beforeCreate() {
            if (!this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchatlogin"
                });
            }
        },
		mounted() {
			this.shopUserInfo = this.$LStorage.getItem("shopUserInfo");
			this.TixianList();
		},
		methods: {
			async TixianList() {
				let res = await TixianList({
					data: {
						shop_id: this.shopUserInfo.shop_id,
						order_status: this.cur,
						pages: this.pages
					}
				}); 
				console.log(res)
				this.isLoad = true;
				if (res.code == 200) {
					this.tixianlist = this.tixianlist.concat(res.data);
					if (res.data.length < res.numPage) {
						this.loadLastText = false;
					} else {
						this.pages++;
						this.loadLastText = true;
					}
				}
			},
			changeTab(cur) {
				this.cur = cur;
				this.tixianlist = [];
				this.pages = 1;
				this.loadLastText = true;
				this.isLoad = false;
				this.TixianList();
			}
		},
		filters: {
			btnText(data) {
				if (data == 0) {
					return "已驳回";
				}
				if (data == 1) {
					return "审核中";
				}
				if (data == 2) {
					return "已提现";
				}
			}
		}
	};
</script>

<style lang="scss">
	.withdetails {
		width: 100%;
		height: 14rem;
		background: #f6f6f6;

		.my-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-sizing: border-box;
			font-size: 0.38rem;
			background: #f4f4f4;
		}

		.withdetails-wrap {
			width: 100%;

			.withdetails-wrap-tab {
				width: 5.8rem;
				margin: 0 auto;
				display: flex;
				padding-top: 0.56rem;
				padding-bottom: 0.3rem;
				border-radius: 0.18rem;

				li {
					width: 1.93rem;
					height: 0.83rem;
					text-align: center;
					line-height: 0.83rem;
					font-size: 0.3rem;
					color: #ff5400;
					border: none;
					border: 1px solid #ff5400;
				}

				li:nth-child(2) {
					border-right: none;
				}

				.quanbu {
					background: #ff5400;
					color: #fff;
				}
			}

			.withdetails-wrap-show {
				width: calc(100% - 0.48rem);
				margin: 0 auto;
				margin-top: 0.3rem;

				.withdetails-wrap-show-1 {
					margin-top: 0.18rem;

					li {
						background: #fff;
						padding: 0.23rem 0.21rem 0.26rem 0.22rem;
						box-sizing: border-box;
						margin-bottom: 0.58rem;

						.withdetails-wrap-show-1-top {
							display: flex;
							justify-content: space-between;

							p:nth-child(1) {
								font-size: 0.27rem;
								color: #363636;
							}

							p:nth-child(2) {
								font-size: 0.23rem;
								color: #ff5400;
								margin-left: 3rem;
							}
						}

						.withdetails-wrap-show-1-bottom {
							display: flex;
							justify-content: space-between;
							margin-top: 0.38rem;

							p:nth-child(1) {
								font-size: 0.27rem;
								color: #a8a8a8;
							}

							p:nth-child(2) {
								font-size: 0.23rem;
								color: #000000;
							}

							.bottom-right {
								display: flex;
							}
						}
					}
				}
			}
		}
	}

	.more {
		font-size: 0.24rem;
		text-align: center;
		margin-bottom: 20px;
	}
</style>
